import React from 'react';

export default function RecipeIngredientEdit() {
  return (
    <>
      <input type='text' className='recipe-edit__input' />
      <input type='text' className='recipe-edit__input' />
      <button className='btn btn--danger'>&times;</button>
    </>
  );
}
